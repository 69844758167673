<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <div style="height: 10px;"></div>
        {{caption}}
        <div class="card-header-actions">
          <div style="float: right; font-size: 12px;">
            <table>
              <tr>
                <td style="width: 250px;">
                    <v-date-picker                    
                      v-model="range"    
                      class="date-picker"
                      locale="es-MX" size="sm"          
                      mode="range"
                      style="width: 250px; font-size: 12px !important;"
                    />  
                </td>
                <td>
                  <CSelect
                    :value.sync="type_id"
                    :options="typesOptions"
                    invalidFeedback="Este campo es requerido."
                    style="width: 250px; font-size: 12px !important; margin-bottom: 0px;"
                    @change="changeFilters"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable style="font-size: 12px;"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #number="{item}"><td class="text-center w50">{{item.number}}</td></template>
        <template #date="{item}"><td class="text-center w100">{{item.date}}</td></template>
        <template #user="{item}"><td class="text-center w200">{{item.user_id > 0 && item.user ? item.user.name : '-'}}</td></template>
        <template #type="{item}"><td class="text-left w300"><b>{{item.type}}</b><br />{{item.description}}</td></template>
        <template #beneficiary="{item}"><td class="text-left w300"><b>{{item.beneficiary_name}}</b><br />{{item.geolocation}}<br />{{item.beneficiary_phone}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

    <BinnacleEventModal ref="eventModal" @store="storeProcess" @update="updateProcess"></BinnacleEventModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import ws from '../../../services/supports';
import store from '../../../store'
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import BinnacleEventModal from './BinnacleEventModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'

export default {
  name: 'BinnacleEventsTable',
  components: { 'v-date-picker': DatePicker, BinnacleEventModal, DeleteModal },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'number', label: 'No.'},
          {key: 'date', label: 'Fecha'},
          {key: 'user', label: '¿Quien reporta?'},
          {key: 'type', label: 'Descripción del Apoyo o Programa'},
          {key: 'beneficiary', label: 'Beneficiario'},
          {key: 'actions', label: 'Acciones'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    stage: {
      type: String,
      default: 'active'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      type_id: 0,
      types: [],
      typesOptions: [],
      range: {
        start: new moment().startOf('year').toDate(),
        end: new moment().endOf("year").toDate(),
      },
      user: null
    }
  },
  mounted: async function() {
    this.loading();

    let response = await ws.getTypes();

    if(response.type == "success"){
      this.types = response.data;

      this.typesOptions = await this.$parseSelectOptions(this.types, "id", "name", [{"label": "Todos","value":""}]);
    }

    this.loaded();
  },
  methods: {
    changeFilters: async function(event){
      this.loading();

      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      let response = await ws.getFilteredSupports(dates, this.type_id);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    updateModal (item) {
      this.$refs.eventModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Registro", item.id, item.description);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  }
}
</script>
<style>
  .date-picker input{
    height: 32px;
    font-size: 12px !important;
  }

  select{
    height: 32px;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
</style>