<template>
  <div>
    <CRow>
      <CCol sm="12">
        <BinnacleEventsTable
          :items="supports"
          :type="code"
          hover
          striped
          border
          small
          fixed
          :caption="title"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import BinnacleEventsTable from '../../components/binnacle/events/BinnacleEventsTable.vue'
import supports from '../../services/supports';
import store from '../../store'

export default {
  name: 'BinnacleEvents',
  components: { BinnacleEventsTable },
  data: function () {
		return {
        code: 'all',
        title: 'Eventos de Bitacora',
        supports: [],
        user: null
    }
  },
  mounted: async function() {
    this.loading();

    this.user = JSON.parse(localStorage.getItem("user"));

    let response = await supports.get();

    if(response.type == "success"){
      this.supports = response.data; 
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.supports = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
